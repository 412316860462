.paragraph {
  font-family: "Newsreader", Courier;
}

.books {
  float: center;
  margin-right: 10px;
}

.HomePage-hero {
  background-image: url("../assets/images/hero.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto;
  padding: 30px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center; /* Centers children horizontally */
  justify-content: center; /* Centers children vertically (if there's enough space) */
  gap: 145px;
}

.HomePage-hero-relative {
  position: relative;
}

.HomePage-hero-text {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(
    -50%,
    -50%
  ); /* Adjust the centering based on the element's size */
}

.HomePage-hero-text button {
  font-family: "Newsreader", Courier;
  /* Use clamp() to dynamically adjust font size */
  font-size: clamp(0.7rem, 2vw, 1.5rem);
  padding: 1.3vw 3vw;
  color: white;
  background-color: #94b4c6;
  border: none;
  border-radius: 2vw;
}

.HomePage-hero-text h1 {
  color: white; /* Text color */
  /* Use clamp() to dynamically adjust font size */
  font-size: clamp(0.7rem, 2vw, 1.5rem);
  font-family: "Trajan", serif; /* Use the imported font */
  font-weight: 100;
}

.HomePage-hero-text h2 {
  color: white;
  /* Use clamp() to dynamically adjust font size */
  font-size: clamp(0.6rem, 2vw, 1.5rem);
  font-weight: 100;
  font-family: "Newsreader", serif; /* Use the imported font */
}

/* Import font */
@font-face {
  font-family: "Trajan";
  src: url("../assets/fonts/TrajanPro-Regular.ttf");
}
/* Import font */
@font-face {
  font-family: "Newsreader";
  src: url("../assets/fonts/Newsreader-VariableFont_opsz\,wght.ttf");
}
/* Responsive settings for images within .HomePage-hero */
.HomePage-hero img {
  max-width: 100%;
  height: auto;
}

/* Adjust .HomePage-hero for smaller screens */
@media (max-width: 480px) {
  .HomePage-hero {
    flex-direction: row; /* Corrected to stack elements vertically on small screens */
    gap: 20px; /* Reduced gap for smaller screens */
    width: 100%; /* Ensure full width */
    height: auto; /* Adjust height automatically */
  }
}

.HomePage-header {
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.HomePage-link {
  color: #61dafb;
}

.HomePage-body {
  position: absolute;
  top: 98%; /* Center vertically */
  left: 10%; /* Center horizontally */
  transform: translate(
    -10%,
    -10%
  ); /* Adjust the centering based on the element's size */
  display: flex;
  flex-direction: row;
  align-items: center; /* Centers children horizontally */
  gap: 50px;
}

.HomePage-body img {
  border-radius: 10vw;
  width: 40vw;
  max-width: 550px;
}

.HomePage-body h1 {
  font-family: "Newsreader", serif; /* Use the imported font */
  /* Use clamp() to dynamically adjust font size */
  font-size: clamp(1.7rem, 2vw, 2.5rem);
  font-weight: 400;
  color: #132633;
}

.HomePage-body p {
  font-family: "Newsreader", serif; /* Use the imported font */
  /* Use clamp() to dynamically adjust font size */
  font-size: clamp(0.7rem, 2vw, 1rem);
  color: black;
  text-align: left;
  margin: 0 auto; /* Centers the text block horizontally */
  word-wrap: break-word; /* Ensures long words do not overflow */
}

.HomePage-body-text {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}
.HomePage-body-text h1 {
  font-family: 'Newsreader', serif;
  font-size: 2em;
  color: #132633;
  text-align: left; /* Explicitly aligns text to the left */
}

.HomePage-body-buttons {
  margin-top: 2vw;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 20px;
}

.HomePage-body-buttons button {
  font-family: "Newsreader", Courier;
  /* Use clamp() to dynamically adjust font size */
  font-size: clamp(0.2rem, 2vw, 1.5rem);
  padding: 1vw 2vw;
  color: white;
  background-color: #132633;
  border: none;
  border-radius: 2vw;
}

@media (max-width: 768px) {
  .HomePage-body {
    flex-direction: column; /* Stack elements vertically on small screens */
    justify-content: center;
    align-items: center;
    width: 100%; /* Adjust this value as needed */
    height: auto; /* Maintain aspect ratio */
  }
  .HomePage-body img {
    width: 70%; /* Make the image take up the full width of its container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Adjust how the image fits within its box, optional */
  }
  .HomePage-body-text h1 {
    /*font-size: clamp(1.2rem, 2vw, 2.5rem);*/
    display: none;
  }
  .HomePage-body-text p {
    /*max-width: 300px;
    font-size: clamp(0.3rem, 2vw, 1rem);*/
    display: none;
  }
  .HomePage-body-buttons {
    display: flex;
    margin: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .HomePage-body-buttons button {
    font-size: 1rem; /* Increase font size */
    padding: 3vw 30vw; /* Provide a consistent padding */
    width: 100%; /* Set a consistent width */
    border-radius: 30px;
    box-sizing: border-box; /* Include padding and border in the element's total width */
    margin: 0 auto; /* Center the button if the container is wider */
  }
}

/*
@media (min-width: 1024px) {
  .HomePage-body h1 {
    font-size: clamp(2.5rem, 2.5vw, 3.5rem); 
  }

  .HomePage-body p {
    font-size: clamp(1rem, 2.5vw, 1.5rem); 
  }
}
  */
