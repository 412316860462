/* Global settings */
* {
  box-sizing: border-box; /* includes padding and border in the element's total width and height */
}

/* Base container */
body {
  /* Browser resets */
  margin: 0;
  padding: 0;

  /* Layout settings */
  min-height: 100vh; /* Makes sure the app container takes at least the full height of the viewport */
  display: flex; /* Optional: For layout purposes */
  flex-direction: column; /* Optional: Stacks children vertically */
  align-items: center; /* Centers children horizontally */
  justify-content: center; /* Centers children vertically (if there's enough space) */

  /* To Do: remove once I figure out import for font */
  /* Font settings */
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased; /* For smoother font rendering on Chrome, Safari, etc. browsers */
  -moz-osx-font-smoothing: grayscale; /* For smoother font rendering on Firefox macOS */
}

h1 {
}

body {
}
