.UnderConstruction-header {
  color: #94b4c6;
  font-family: "Trajan", serif;
  font-size: clamp(2rem, 2vw, 2rem);
  text-align: center;
  margin-bottom: 2rem;
  padding-top: 50px;
}

.UnderConstruction-content {
  font-family: "Newsreader", Courier;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

