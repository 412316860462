.HomePage {
    text-align: center;
    min-height: 100vh; /* Ensures the page covers the viewport height */
    width: 100vw;
  }

.container {

}

.flex-row {

}

.flex-column {

}

.heading-1 {
    
}