.AboutPage-container {
  position: relative;
  padding: 5vw;
  color: #333;
  text-align: left;
  background-color: #f0f4f8;
}

.AboutPage-header {
  position: relative;
  color: #94b4c6;
  font-family: "Trajan", serif;
  font-size: clamp(1.5rem, 5vw, 3rem);
  margin-bottom: 2rem;
}

.AboutPage-content {
  position: relative;
  text-align: left;
  font-family: "Newsreader", Courier;
  font-size: clamp(0.8rem, 2vw, 1.2rem);
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

.AboutPage-header h1 {
  position: relative;
  color: #94b4c6;
  font-family: "Trajan", serif;
  font-size: clamp(2rem, 5vw, 3rem);
  margin-bottom: 2rem;
}

.AboutPage-content h2 {
  color: #333;
  font-size: clamp(1.2rem, 4vw, 2rem);
}

.AboutPage-content p {
  margin-bottom: 1rem;
}

.AboutPage-backLink {
  display: inline-block;
  margin-top: 2rem;
  font-family: "Newsreader", Courier;
  font-size: clamp(0.7rem, 2vw, 1.5rem);
  padding: 1vw 2vw;
  color: white;
  background-color: #132633;
  text-decoration: none;
  border-radius: 2vw;
}
