.header {
  display: flex;
  /*
  position: fixed;
  top: 0;
  z-index: 1000; /* Ensures the header stays on top of other content */
  flex-direction: row;
  height: auto;
  padding: 1rem 3rem;
  width: 100%;
  font-family: "Newsreader", sans-serif; /* Use custom Newsreader font with a sans-serif fallback */
  justify-content: space-between;
  align-items: center;
}

/* Import font */
@font-face {
  font-family: "Newsreader";
  src: url("../../../assets/fonts/TrajanPro-Regular.ttf");
}

/* Mobile settings */
@media (max-width: 480px) {
  .header {
    padding: 1rem 0.5rem;
    font-size: 0.75rem;
  }
}
